$greyColor :  rgba(0, 0, 0, 0.4);
$darkGreyColor :  rgba(0, 0, 0, 0.7);
$blackColor : rgb(0, 0, 0);
$whiteColor :  rgb(255, 255, 255);

@mixin themeCreation ($theme-name,$bg-color,$text-color){
//.#{$theme-name}{
    // div{
    //     background-color: $bg-color;
    // }
    header{
        background-color: $bg-color ;
        color:$text-color ;
    }
    .bg-white{
        background-color: $bg-color;
    }
     .App{
        color: $text-color ;
        background-color: $bg-color ;
    }
    // .trailer{
    //   @include cardSize(14vw,140px,27.2vw,391px,14vw,)
    // }
    // .thumbnail{
    //   @include cardSize(14vw,140px,27.2vw,391px)
    // }
    .th-black{
      background-color: $bg-color;
    }
    .Footer{
      min-height:auto;
      // max-width: 90% !important;
      margin: 0px 16px;
    }
    .Footer h3 {
     // margin-right: 25px;
      font-size: 15px;
      font-family: 'SFProDisplay-Semibold';
      font-stretch: normal;
      line-height: 1.6;
      letter-spacing: -0.26px;
      color: #fff;
      font-style: normal;
    }
    .Footer .footer-h3 {
      font-size: 15px;
      font-family: 'SFProDisplay-Regular';
      font-stretch: normal;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.26px;
      color: #8e8e93;
      font-style: normal;
    }
    // .Footer p {
    //   font-size: clamp(17px,1.3vw,1.3vw);
    // }
    .footerText {
      margin-top: 80px;
    }
    .channelCardContainer{
      display : flex;
      justify-content: space-between;
    }
    .channelHome{
      max-width: 33vw;
    }
    .mobileScroll::-webkit-scrollbar{
      display:none
    }
    .channel-desc{
      font-size: 14px;
    }
    // .MuiTypography-h1 {
    //   font-size: 22px !important;
    // }
    // .MuiTypography-h6 {
    //   font-size: 17px!important;
    // }
    .MuiTypography-caption {
      font-family: SFProDisplay-Regular ;
    }
//}
}

@mixin cardSize ($height,$minHeight,$width,$minwidth,$maxheight,$maxWidth) {
    height: $height !important;
    min-height: $minHeight !important;
    max-height: $maxheight !important;
    max-width: $maxWidth !important;
    width: $width !important;
    min-width: $minwidth !important; ;
    transform: translate3d(0px, 0px, 0px);
}

@import "./icon.scss";

// html{
//     background-color: $blackColor;
// }
// header{
//     background-color: $blackColor !important;
//     color:$whiteColor !important;
// }
//  .bg-white{
//      background-color: $blackColor !important;
//  }
// .App{
//     color: $whiteColor 
// }
.imageHolder {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: baseline;
    align-items: baseline;
    border-radius: 8px ;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    z-index: 1;
  }
  .full-overlay::after{
    content: '';
    position : absolute;
    width: 100%;
    height:100%;
    background-color: $greyColor;
    z-index: -1;
    border-radius: 8px  ;
  }
  .full-dark-overlay::after{
    content: '';
    position : absolute;
    width: 100%;
    height:100%;
    background-color: $darkGreyColor;
    z-index: -1;
    border-radius: 8px  ;
  }
  .cotd-overlay::after{
    content: '';
    position : absolute;
    width: 100%;
    height:30%;
    background-color: rgba(0, 0, 0, 0.32);
    z-index: 0;
    border-radius: 8px 8px 0 0 ;
  }
  .contentTop {
    display: flex;
    position: absolute;
    transition: all 230ms ease-in-out 0s;
    top: 17px;
    left: 15px;
    width: 100%;
    z-index: 1;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  
  .classnameHolder {
    display: flex;
    line-height: 1;
  }
  .bottomHolder {
    display: flex;
    line-height: 1;
    color: white !important;
    text-align: left;
    margin-right: 4vw;
  }
  .classbadge {
    margin-right: 0px;
    margin-top: 0.166667rem;
  }
  .time {
    color: $whiteColor;
    font-weight: 600;
    font-size: 17px;
    //text-transform: uppercase;
    display: inline-block;
    letter-spacing: -0.2px;
    line-height: 24px;
    text-align: left;
    font-family:SFProDisplay-Semibold;
    margin-bottom: 5px;
  }
  .timeholder {
    text-align: left;
    display: grid;
    }
    .comman{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    }
  // width: calc((100vw - totalMargins - totalGridGap)/no Of cards)
  $widthLCC:calc((100vw - 32px - 8px)/1.10); //refer line 192 for understanding width calc bifurcation 
  .live-channel-collection {
    @include cardSize(auto,auto,$widthLCC,$widthLCC,auto,$widthLCC);
    padding-top: 56.3%;
    position: relative;
  }
  $widthCotd:calc((100vw - 32px)) ; //refer line 192 for understanding width calc bifurcation 
  .channel-page-card {
    @include cardSize(auto,auto,$widthCotd,$widthCotd,auto,$widthCotd);
      width: $widthCotd ;
      padding-top: 56.3%;
      position: relative;
  }
  .browseClasses{
    @include cardSize(100px,100px,100px,100px,100px,100px);
    overflow-y: hidden; 
    scrollbar-width: none;
    @media only screen and (max-width: 767px) {
    .MuiTypography-body2{
      font-size: 13px;
    }
    }
  }
  $heightBrowseClassesLanding:calc((100vw - 48px)/3); //refer line 192 for understanding width calc bifurcation 
  .browseClasses-landing{
    @include cardSize($heightBrowseClassesLanding,$heightBrowseClassesLanding , 100%,100% ,$heightBrowseClassesLanding , 100%)
  }
  .cotd-card{
    @include cardSize(  auto, auto, $widthCotd, $widthCotd, auto, $widthCotd);
    padding-top: 56.3%;
    position: relative;
    
    
  }
  .cardPos {
    display: flex;
    border-radius:8px ;
    background-color: transparent;
    flex-shrink: 0;
  }
  
  .list {
  width:100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1fr;
  grid-gap: 8px; 
  overflow-x: scroll; 
  scroll-behavior: smooth;
  scrollbar-width: none;
  

  }

  .cotd-list{
    overflow-x: hidden !important;
  }
  .list-browseClasses{
    grid-template-columns: repeat(autofill,170px);
    overflow-x: scroll !important;
    scroll-behavior: smooth;
    scrollbar-width: none;
  }
  .list-browseClasses-landing {
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: row ;
  }
  
  .border-radius{
    border-radius:0px
  }
  .list::-webkit-scrollbar{
    display: none;
  }
  .channelImg {
      position: absolute;
      background-color: #fff;
      width:80px;
      height:80px;
      top: 64.5px;
      left: 16px;
      transition: all 230ms ease-in-out 0s;
}
.bottomHolder .font-caption {
  font-size: 15px !important;
  position: absolute;
  transition: all 230ms ease-in-out 0s;
  top: 60px;
  left: 111px;
}  
.channelname {
  font-size: 22px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #fff;
}
 .contentTop {
  display: flex;
}
  .App {
    &.theme-black{
      @include themeCreation (theme-black,$blackColor,$whiteColor);
    }
    &.theme-white{
      @include themeCreation (theme-white,$whiteColor,$blackColor)
    }
  }
  .page-container {
    position: relative;
    margin: 0 16px;
    padding: 0;
    -webkit-transition: margin 0.5s linear 0s;
    -moz-transition: margin 0.5s linear 0s;
      -o-transition: margin 0.5s linear 0s;
  }
  .card-footer{
    display: grid;
    justify-content: left;
    width: 100%;
    margin: 9px 0 6.2px 0px;
  }
  .m-b-8{
    margin-bottom: 8px;
  }
  .m-b-5{
    margin-bottom: 5px; 
  }
  .live-title{
    font-family: SFProDisplay-Semibold;
    color: white;
    font-size:17px;
    display: inline-block;
    letter-spacing: -0.2px;
    line-height: 24px;
    text-align: left;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    font-weight: 500;
  }
    .live-time{
    font-family: SFProDisplay-Semibold;
    color: white;
    font-size: 17px;
    display: inline-block;
    text-align: left;
    font-weight: bold;
    letter-spacing: -0.15px;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    font-stretch: normal;
    }
    .font-h6{
      display: none;
    }
    .collection-caption{ 
      font-size: 20px !important;
      font-family: 'SFProDisplay-Semibold';
      line-height: 1 ;
      font-stretch: normal;
      letter-spacing: -0.8px;
      margin:0
    }
    // .m-t-12{margin-top: 17px;}
    .right-arrow{
      margin-left: 8px;
    }
    .MuiToolbar-regular {
      min-height: 0px !important;
  }
  .footer-lower{
    background-color: #000000;
    padding: 13px 16px;
    margin-top: 24px;
  }
  .footer-lower p{
    color: #8e8e93 !important
  }
  .hero-img-size{
    height: 233px;
    background-size:cover;
    color:white !important;
  }
  .hero-button{
    margin-top: 20px !important;
    .MuiTypography-button{
      font-size: 15px;
      font-family: SFProDisplay-regular;
    }
      width:132px !important;
      height:28px !important;
  }
  
  .container-1{
    margin-right: 56px;
    margin-bottom: 16px;
  }
  
  .EContainer{
    margin: auto ;
  }
  .class-title-text-holder {
    margin: 0px 16px;
    padding-top: 24px;
    h1,h3{
      padding: 0px 20px;
    }
  }
  .truncate {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-right: 2.5vw
  }
  .SubscriptionHolder {
      display: block;
      margin-top: 57px !important;
      padding: 0px 9px !important;
  }
  .market-tag-h1{
    font-size: 22px !important;
    font-family: 'SFProDisplay-Semibold' !important;
    margin-bottom: 16px !important;
    @media (min-width:768px) {
      font-size: 28px !important;
      margin-bottom: 8px !important;
    };
    @media (min-width:1360px) {
      margin-bottom: 8px !important;
      font-size: 40px !important;
    }
    @media (min-width:2000px) {
      margin-bottom: 8px !important;
    }
  }
  .lp-browse-class-container{
    .m-t-60{
      margin-top: 18px;
    }
  }
  .list-live-page{
    grid-template-columns: 1fr;
    grid-auto-flow: row ;
    @media (max-width:767px) {
      grid-gap: 16px 8px;
    }
    @media (min-width:768px) {
      grid-gap: 24px 8px !important;
    }
    @media (min-width:1366px) {
      grid-gap: 30px 16px !important;
    }
  }
  .live {
    margin-top: 18px !important;
    @media (min-width:1360px){
      margin-top: 32px !important;

    }
  }
  .topProfileLink{
    margin-top: 24px  !important;
    p {
      font-family: 'SFProDisplay-Semibold';
      line-height: 1; 
      font-size: 17px}
  }
  // .res-fav-acc{
  //   .MuiTypography-body1 {
  //     line-height: 1; 
  //    font-size: 17px !important
  //   }
  // }
  .page-heading{
    position:absolute;
    top:41px;
    left:16px;
    text-align: left !important;
    h1{
      font-size: 22px;
    }
    h6 {
      display: none;
    }
  }
  .channelbgimg {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    @media (min-width:768px) {
      margin-bottom: 9px;
    }
  }
  .channel-profileImg {
    margin-right: 15px;
    width: 48px;
    height: 48px;
    cursor: pointer;
    background-color: white;
    border-radius: 4px
  }
  .main-page-container{
    margin: 24px 16px;
    min-height: 50vh;
  }
  
  .main-margin{
    margin-top: 18px;
    @media (min-width:768px) {
      margin-top: 28px;
    }
  }

  .collection-page-list{
    padding-top: 24px;
    margin-bottom: 32px;
  }
  .collectionPage{
    h1{
      font-family: SFProDisplay-Semibold;
      font-size: 22px !important;
    }
  }

  .collection-heading{
    margin: 22px 0 26px 0;  
    h1{
      font-size: 22px;
      margin-bottom: 0;
    }
    h6{
      margin-top:8px;
      font-family: 17px;
    }
  }
  .browseCategory{
    .makeTextClickable{
      font-size: 22px!important;
    }
  }
  .category-tabs{
    margin-top: 8vw;
    margin-left: 4vw;
    margin-right: 4vw;
    button{
      font-size: 22px !important;
      font-family: SFProDisplay-Semibold;
    }
  }
  .category-page-heading{
    position:absolute;
    top:74px;
    left: 32px;
    text-align: left;
    h1{
      display: none !important;
    }
  }
  .category-page{
      // width: 100vw;
  //  .list-container-gap{
  //   //padding-top: 10px;
  //  }
   .collectionShimmer{
    margin-top: 24px;
   }
   .bg-banner{
    height: 29vw;
    object-fit: cover;
    // changing 2px back to 8px the blur
    filter:blur(8px); 
    -webkit-filter: blur(8px);
   }
  }
  .topkeyword{
      display: block;
      font-size: 15px!important;
    }
  .discIcon::before{
   display: none !important;
  }
  .discIcon::after{
    margin:0 8px;
    content: "\2022";
    width: 20px;
    position: relative;
    height: 20px;
    font-size: 20px;
    line-height: 18px;
    color: #000000;
  }
  .meta-desc{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .font-proDisplayBold{
    font-size: 13px !important;
    margin-bottom: 16px !important;
  }
  .p-t-player{
    padding-top: 22px;
  }
  .labelStyles{
    padding: 5;
    font-family:SFProDisplay-Regular !important;
    font-size:17px !important;
    margin: 0 24px!important;
    white-space: nowrap !important;
  }
  .levelStyles{
    font-family: SFProDisplay-Regular !important;
    text-align: end;
    color: #8e8e93;
    font-size: 13px !important;
    margin-bottom: 0px !important;
  }
  .video-wrapper {
    width: 100vw;
    height: auto;
    margin-left: -16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .customh1 {
    font-family: SFProDisplay-Semibold;
    font-size: 22px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.2px;
    color: #000000;
    text-align: left;
    margin-block-start: 0px !important;
    margin-block-end: 0px !important;
  }
  .vido-metadata-row {
    display: flex;
    flex-direction: column;
    line-height: 1 !important;
    justify-content: space-between;
    margin-top: 24px;
  }
  .progress-container{
    margin-top: 20px;
    justify-content:space-between;
    position: relative;
    display:none
  }
  .bar-container{
    position: absolute;
    right:0;
    left:143px;
  }
  .barContainerStyles{
    height: 8px;
    width: 100%;
    background-color: #e0e0de;
    border-radius: 50px;
    margin-bottom: 16px
  }
  .featured-iconText-Player {
    font-family: 'SFProDisplay-Semibold';
    font-size: 17px !important;
    line-height: 1.47;
    color: #8e8e93;
  }
  .new-video-metadata {
    text-align: left;
    margin-top: 24px;
    h6 {
      line-height: 24px;
    }
  }
  .class-side-info{
    margin-top: 32px;
    margin-bottom: 58px;
    .font-proDisplayBold{
      font-size: 17px !important;
      display: flex;
      .value{
        font-size: 17px !important;
        }
      }
      .arrow-field{
        vertical-align: middle;
        height: 24px;
        width: 24px;
        margin-left: 4px;
      }
  }
  .more-class-arrow{
    vertical-align: middle;
    height: 32px;
    width: 32px;
    margin-left: 4px;
  }
  .livePlayer .workOutCompleted {
    width: 100% !important;
    height: -webkit-fill-available !important;
  }
  .more-img{
    display: none;
  }
  .meta-arrow{
    width: 24px !important;
    height: 24px !important;
    vertical-align: middle;
  }
  .topkeyword{
    margin-bottom: 8px !important;
  }
  .sign-in-button{
  .MuiTypography-button{
    font-family: SFProDisplay-Regular !important;
    font-size: 15px !important;
    }
  }
  .sign-up-button{
    .MuiTypography-button{
    font-family: SFProDisplay-Regular;
    font-size: 15px;
    }
  }
  .subscribe-input {
    font-size: 17px !important;
    font-family: SFProDisplay-Semibold !important;
  }
  .subscribe-field{
    font-size: 15px !important;
    min-height: 33px;
    padding-top: 30px;
    font-family: SFProDisplay-Regular !important;
    color: #a3a3a8;
  }
  .subscribe-status{
    font-size: 15px !important;
  }
  .button-link{
    font-size: 17px !important;
    font-family: SFProDisplay-Regular !important;
  }
  .password{
    font-size: 22px !important;
    font-family: SFProDisplay-Semibold !important;
    color:#000000
  }
  .login-title, .err-header, .reset-password, .subscription-title{
    font-size: 22px !important;
    font-family: SFProDisplay-Semibold !important;
    h1{
      font-size: 22px !important;
      font-family: SFProDisplay-Semibold !important;    
    }
  }
  .login-disclaimer{
    font-size: 13px !important;
  }
  .m-t-contact{
    margin-top: 16px;
  }
  .m-t-contact-button{
    margin-top: 18px !important;
  }
  .left-arrow {
    top: -5px;
    left: 0px;
    position: absolute;
    cursor: pointer;
  }
  .m-t-account{
    margin-top: -10px;
  }
  .title-font{
    h1{
      font-family: SFProDisplay-Semibold !important;
    }
  }
  .formContainer{
    margin: 10px auto 0px auto;
  }
  form.editForm {
    width: 100%; //need to apply it after restructuring forms
    max-width: 500px;
    margin: 8px auto;
  }
  .livecard-label{
    position: absolute;
    right: 15px;
  }
  .live-label {
    font-size: 10px;
    vertical-align: middle;
  }
  .rec-class-title{
      margin-top: 32px !important;
      margin-bottom: 24px !important;
     p {
      font-family: SFProDisplay-Semibold !important;
     }
  }
  .m-b-contact{
    margin-bottom: 70px !important;
  }
  .player-border{
    border-radius: 0px;
  }
  .more-heading{
   p {
    font-size: 20px !important;
    font-family: SFProDisplay-Semibold !important;
   }
  }
  .m-more-class{
    margin-top: 52px;
  }
  .m-more-head{
    margin: 26px 0px !important;
  }
  .livePlayer-mt{
    margin-top: 26px;
  }
  .topProfileLink-margin{
    margin-right: 16px !important;
  }
  .lang-sel-cont{
    margin-top: 8px !important;
    min-width: 210px;
  }
  .label-logo{
    width: 26px
  }
  .headerLinks-fitness{
    font-family: SFProDisplay-Semibold !important;
  }
  .livePlayer-info2{
    position: absolute;
    text-align: left;
    top: 42px;
    z-index: 10;
  }
  .live-leave{
    position: absolute;
    width:100vw;
    height: 54vw;
    top: 27px !important;
    left:auto !important;
  }
  .m-payment-Done-btn{
    margin-top: 48px!important;
  }
  .no-classes-found{
    margin-top: 48px;
    p{
      font-family: SFProDisplay-Regular;
      font-size: 17px;
    }
  }
  .class-side-info-shimmer{
    width:100%
  }
  .Trailer, .thumbnail, .Trailer:focus{
    height: 100% !important;
    max-height: 52vw !important;
  }
  .height-fix{
    height: 30.05px !important;
  }
  [name='accessCode'].MuiInputBase-input::-webkit-input-placeholder{
    opacity: 1;
  }
  .form-container{
    width:100%
  }
  .check-box-align {
    margin: -3px 0px 0px -13px !important
  }
  .m-t-signup-btn{
    margin-top: 12px;
  }
  .m-t-login-btn{
    margin-top: 26px !important;
  }
  .m-tb-xs-5{
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .not-found{
    margin: 20px 16px 0px 16px
  }
  .m-t-xs-sent-mail{
    margin-top: 15px;
  }
  .m-t-sent-mail{
    margin-top: 26px !important;
  }
  .m-t-xs-account{
    margin-top: 18px;
  }
  .m-t-payment{
    margin-top: 30px !important;
  }
  .m-b-reset-pass{
    margin-bottom: 40px !important
  }
  .MuiFormHelperText-root.Mui-error{
    color: #ff0000 !important;
    font-size: 13px;
  }
  .loader-caption{
    font-size: 20px;
  }
  .shareViewRowWidth{
    width:100%;
    justify-content:space-between
  }
  .shareLiveViewRowWidth{
    min-width:95%;
    justify-content:space-between
  }
  .live-label-player{
    text-transform: uppercase;
    border-radius: 4px;
    background-color: #ff3b30;
    color: #fff;
    padding: 0px 5px 0 4px;
    margin-right: 8px;
    font-size: 17px;
    font-family: SFProDisplay-Semibold;
  }
  .live-title-truncate{
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-right: 47px
  }
  .single-truncate{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  }
  .title-truncate{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  }

  .share-btn-align{
    justify-content: end;
  }

  .padding-top-tnc-accept{
    padding-top: 10px;
  }
  .sharePadding16Right{
    padding-right: 8px;
  }
  .remove-pointer-events{
    pointer-events: none !important
  }

  @media only screen and (min-width: 768px)  {
    $widthLCC:calc((100vw - 64px - 16px - 8px )/3); //refer line 192 for understanding width calc bifurcation 
    $widthCotd:calc((100vw - 64px - 16px - 8px)/3) ;
    .list {
      overflow-x: hidden;
      grid-template-columns: repeat(3,$widthLCC);
    }
    .font-h6{
      display: block;
    }
    .list-browseClasses{
      grid-template-columns: repeat(auto-fill,132px);
      overflow-x: scroll;
      scroll-behavior: smooth;
      scrollbar-width: none;
      
    } 
    .cotd-card{
    position: relative;
    @include cardSize(  auto, auto, $widthCotd, $widthCotd, auto, $widthCotd);
    width: $widthCotd !important;
    top: 0;
    padding-top: 56.3%;
    height: auto !important
    } 
    .browseClasses{
      @include cardSize(132px,132px,132px,132px,132px,132px);
      overflow-y: hidden; 
      scrollbar-width: none;
    }
    .live-channel-collection {
      @include cardSize(auto,auto,$widthLCC,$widthLCC,auto,$widthLCC);
      width: $widthLCC;
      padding-top: 56.3%;
      position: relative;
    }
    .channel-page-card {
      @include cardSize(auto,auto,$widthLCC,$widthLCC,auto,$widthLCC);
        width: $widthCotd ;
        padding-top: 56.3%;
        position: relative;
    }
    .page-container {
      position: relative;
      margin: 0 32px;
      
    }
      .channelImg {
        width: 56px;
        background-color: #fff;
        position: absolute;
        transition: all 230ms ease-in-out 0s;
        top: 40px;
        left: 8px;
        height: 56px;
      }
      .contentTop{
        position: absolute;
        transition: all 230ms ease-in-out 0s;
        top: 8px;
        left: 8px;;
      }
      .classname {
        font-size: 20px !important;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        color: #fff;
      }
      .channelname {
        font-size: 15px !important;
      }
      .bottomHolder{
      margin-right: 1.3vw
      }
      .bottomHolder .font-caption{
        font-size: 11px !important;
        position: absolute;
        transition: all 230ms ease-in-out 0s;
        top: 40px;
        left: 72px;
      }
      .cotd-date{
        font-size:  17px !important;
        font-family: SFProDisplay-Semibold !important;
      }
      .m-b-8{
        margin-bottom: 5px;
      }
      .m-b-5{
        margin-bottom: 8px !important; 
      }
      .live-title{
        color: white;
        font-size: 15px;
        display: inline-block;
        letter-spacing: -0.2px;
        line-height: 24px;
        text-align: left;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: normal;
        font-weight: 500;
        }
        .live-time{
        color: white;
        font-size: 15px;
        display: inline-block;
        text-align: left;
        font-weight: bold;
        letter-spacing: -0.15px;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        font-stretch: normal;
        }
        .imageHolder {
          border-radius: 4px;
        }
        .imageHolder::after{
          border-radius: 4px;
        }
        .cotd-overlay::after {
          border-radius: 4px 4px 0 0}
        .footer-lower{
          background-color: #000000;
          padding: 13px 32px;
        }
        .Footer{
          margin: 0px 32px !important;
        }
        .footer-lower p{
          color: #8e8e93 !important
          
        }
        .time{
          margin-bottom: 0px;
          font-size: 15px !important;
        }
        .font-caption{
          font-size: 13px !important;
        }
        .hero-img-size{
          height: 412px;}
          .home-content{
            padding: 0px;
          }
        .class-title-text-holder{
          position:relative;
          margin: 0px 16px;
          background-color: white;
          border-radius: 4px 4px 0px 0px;
          padding-top: 16px;
          h1,h3{
            padding: 0px 73px;
          }
        }
        .EContainer{
          margin: -6% auto 0px auto ;
        }
        .lp-browse-class-container{
          position:relative;
          margin: 0 48px;
          .m-t-60{
            margin-top: 0px;
          }
          .m-t-15{
            margin-top: 0px !important;
          }
        }
        .SubscriptionHolder {
          display: block;
          margin-top: 56px !important;
          padding: 0px 73px !important;
      }

        $heightBrowseClassesLanding:calc((100vw - 160px)/5);
        .browseClasses-landing{
          @include cardSize($heightBrowseClassesLanding,$heightBrowseClassesLanding , 100%,100% ,$heightBrowseClassesLanding , 100%)
        }
        .list-browseClasses-landing {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          grid-auto-flow: row ;
        }
        .list-live-page{
          grid-template-columns: $widthLCC $widthLCC $widthLCC;
          grid-auto-flow: row ;
        }
        .topProfileLink{
          margin-top: 29px  !important;
          p {
           line-height: 1; 
          font-size: 22px }
        }
        .mobileScroll {
          overflow-x: auto;
          overflow-y: hidden;
          .makeStyles-root-7 {
            padding: 11px 16px;
            flex-wrap: nowrap !important;
           
        }
        }
        .main-page-container{
          margin: 0 16px;
          padding: 0 16px;
          margin-top: -116px;
          background-color: white;
          position: relative;
          border-radius: 4px;
        }
        .list-container-gap{
          padding-top: 20px;
        }
        .pseudo-div{
          position: relative;
          margin-top: -48px;
          background-color: white;
          height: 76px;
        }
        .collectionPage-main{
          .pseudo-div {
            margin-top: -74px;
          }
        }
        .page-heading{
          position:absolute;
          top:4.90vw;
          left:4vw;
          text-align: left !important;
          h1{
            font-family: SFProDisplay-Semibold !important;
            font-size: 28px !important;
            line-height: 1;
            margin-bottom: 8px;
          }
          h6 {
            display: block;
          }
        }
        .detail-page{
          .page-heading{
            top: 3vw;
            margin-right: 32px;
          }
        }
        .collection-page-list{
          padding-top: 10px;
          margin-bottom: 32px;
        }
        .collectionPage{
          h1{
            font-size: 22px !important;
          }
        }
        .collection-heading{
          margin-bottom: 36px;
          h1{
            font-size: 28px;
            font-family:SFProDisplay-Semibold;
          }
          h6{
            margin-top:8px;
            font-size: 13px;
          }
        }
        .category-tabs{
          margin-left:32px;
          margin-right:32px;
          margin-top: 16px;
          button{
            font-size: 22px !important;
            font-family: SFProDisplay-Semibold;

          }
        }
        .category-page-heading{
          position:absolute;
          top:74px;
          left: 32px;
          text-align: left;
          h1{
            display: none !important;
          }
        }
        .category-page{
         .list-container-gap{
          padding-top: 10px;
         }
         .collectionShimmer{
          margin-top: 16px;
         }
         .bg-banner{
          height: 29vw;
          object-fit: cover;
         }
         .main-page-container{
          margin-top: -12vw !important;
         }
        }
        .info-container{
          display: flex;
        }
        .meta-desc{
          width:61.2vw;
          align-items: center;
        }
        .video-wrapper {
          height: auto;
          width: 61.2vw;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: normal; 
          overflow: hidden;
          margin-left: 0px;
          border-radius: 12px;
        }
        .class-side-info {
          margin-top: 0; 
          margin-left: 24px;
          .font-proDisplayBold{
            font-size: 13px !important;
            margin-bottom: 8px !important;
            .value{
              font-size: 13px !important;
              }
          }
          .arrow-field{
            vertical-align: middle;
            height: 20px;
            width: 20px;
            margin-left: 4px;
          }
        }
        .more-class-arrow{
          vertical-align: middle;
          height: 24px;
          width: 24px;
          margin-left: 4px;
        }
        .new-video-metadata {
        width: 61.2vw;
        h6 {
          font-size: 13px !important;
        }
        }
        .progress-container{
          justify-content:space-between;
          display:flex;
        }
        .intesity-logo{
          width: 22px;
          height:31px
        }
        .skill-logo{
          width: 26px;
          height:26px
        }
        .hero-button{
          margin-top: 12px !important;
        }
        .more-img{
          display: inline-block;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          margin-right: 16px;
        }
        .video-js{
          width: 61.2vw;
          height: 34.5vw;
        }
        .sign-up-button{
          .MuiTypography-button{
          font-family: SFProDisplay-Regular;
          font-size: 15px;
          }
        }
        .staticBlock.scroll > div {
          margin: 0 32px;
          width: auto;
        }
        .subscribe-input {
          font-size: 20px !important;
          font-family: SFProDisplay-Semibold !important;
        }
        .subscribe-status{
          font-size: 13px !important;
        }
        .login-title, .err-header, .reset-password, .subscription-title{
          font-size: 28px !important;
          font-family: SFProDisplay-Semibold !important;
          h1{
            font-size: 28px !important;
          }
        }
        .login-subtitle h6, .subscription-sub-title{
          font-size: 13px !important;
        }
        .m-t-subs{
          margin-top: 20px !important;
        }
        .m-t-account{
          margin-top: 18px;
        }
        .live-label {
          font-size: 8px;
        }
        .livecard-label {
          right: 12px;
        }
        .player-border{
          border-radius: 16px !important;
        }
        .progress-bar-container{
          margin-top: 40px;
        }
        .m-more-head{
          margin: 26px 0px 10px 0px !important;
        }
        .livePlayer-mt{
          margin-top: 26px;
        }
        .rec-class-title{
          margin-top: 64px !important;
          margin-bottom: 16px !important;
        }
        .topProfileLink-margin{
          margin-right: 24px !important;
        }
        // .res-fav-acc{
        //   .topProfileLink-margin{
        //   margin-bottom: 4px !important;
        //   }
        // }
        .marketing-banner{
          position:absolute;
          top:-4vw !important;
        }
        .collection-caption-cont{
          margin:8px 0 0 0;
        }
        .loginButtonHolder a .MuiTypography-button {
          font-size: 15px ;
        }
        .headerLinks-fitness {
          margin-left: 24px;
        }
        .logoHolder {
          width: 33.5%;
        }
        .container-2{
          flex-basis: 74%;
        }
        .lang-sel-cont{
          margin-top: 8px !important;
          min-height: 48px;
        }
        .more-heading{
          p {
           font-size: 17px !important;
          }
        }
        .live-leave{
          width: 61.2vw;
          height: 34.5vw;
          top: 26px !important;
          border-radius: 16px !important;
        }
        .livePlayer-info2{
          top: 27px;
          width:61vw
        }
        .newStrip{
          border-radius: 16px
          }
        .card-footer{
          margin-top:2px
        }
        .m-b-contact{
          margin-bottom: 70px !important;
        }
        .m-payment-Done-btn{
          margin-top: 54px!important;
        }
        .topKeySpace{
          .MuiTypography-caption{
            font-size: 13px;
          }
        }
        .topkeyword{
          font-size: 13px !important;
        }
        .featured-iconText-Player {
          font-size: 22px !important;
        }
        .marginFormTop {
          margin-top: 52px;
        }
        .m-t-forget-form{
          margin-top: 30px !important;
        }
        .no-classes-found{
          margin-top: 48px;
          p{
            font-size: 13px;
          }
        }
        // .class-side-info-shimmer{
        //   position: absolute;
        //   right: 0;
        // }
        .Trailer, .thumbnail, .Trailer:focus{
          max-height: 16.6vw !important;
        }
        .height-fix{
          height: 27.11px !important;
        }
        .form-container{
          width:500px;
          margin: 10px auto;
        }
        .not-found{
          margin: 20px auto 0px auto !important
        }
        form.editForm {
          margin: 30px auto;
        }
        .m-b-reset-pass{
          margin-bottom: 48px !important
        }
        .m-t-subscription-title{
          margin-top: 4px !important;
        }
        .loader-caption{
          font-size: 22px;
          }
        .shareViewRowWidth{
          width: 61.2vw;
        }
        .shareLiveViewRowWidth{
          min-width: 58.6vw;
        }
        .live-label-player{
          font-size: 20px;
        }
        .fac-subs{
          width: 100%;
          max-width: 907px;
          button {
            margin-top: 32px !important;
            max-width: 500px;
          }
        }
        .share-btn-align{
          margin-top: 8px;
        }
        .padding-top-tnc-accept{
          padding-top: 25px;
        }
  }
  
 
  @media only screen and (min-width: 1360px)  {
    $widthLCC:calc((100vw - 160px - 48px - 16px)/4); //refer line 192 for understanding width calc bifurcation 
    $widthCotd:calc((100vw - 160px - 32px - 16px)/3);
    $widthBrowseClasses:calc((100vw - 160px - 160px - 16px)/10);
    .list{
      grid-template-columns: repeat(4,$widthLCC);
      grid-gap: 16px;
      overflow-x: hidden;
    }
    .imageHolder::after {
      border-radius: 8px;
    }
    .imageHolder {
      border-radius: 8px ;
    }
    .cotd-overlay::after {
      border-radius:8px 8px 0 0 
    }
    .footer-lower{
      background-color: #000000;
      padding: 13px 80px;
      margin-top: 24px; 
    }
    .footer-lower p{
      color: #8e8e93 !important;
      font-size: 12px;      
    }

    .live-channel-collection {
        @include cardSize(auto,auto,$widthLCC,$widthLCC,auto,$widthLCC);
        width: $widthLCC;
        padding-top: 56.3%;
        position: relative;
    }
    .channel-page-card {
      @include cardSize(auto,auto,$widthLCC,$widthLCC,auto,$widthLCC);
        width: $widthCotd ;
        padding-top: 56.3%;
        position: relative;
    }
    .channelImg{
      width:80px;
      height:80px;
      top: 64.5px;
      left: 16px;
    }
    .channelname {
      font-size: 22px !important;
    }
    .contentTop{
      top: 17px;
      left: 15px;
    }
    .bottomHolder .font-caption {
      font-size: 15px !important;
      position: absolute;
      transition: all 230ms ease-in-out 0s;
      top: 60px;
      left: 111px;
    }  
    .browseClasses{
      @include cardSize(170px,170px,170px,170px,170px,170px);
      overflow-y: hidden; 
      scrollbar-width: none;
    }
    .cotd-list{
      grid-template-columns: repeat(3,$widthCotd);
      grid-gap: 16px;
    }
    .list-browseClasses{
      grid-template-columns: repeat(auto-fill,170px);
      overflow-x: scroll;
      scroll-behavior: smooth;
      scrollbar-width: none;
      grid-auto-flow: row !important ;
    }
    .page-container {
      position: relative;
      margin: 0 80px;
    }
    .cotd-card{
      @include cardSize(auto,auto,$widthCotd,$widthCotd,auto,$widthCotd);
      width: $widthCotd !important;
      top: 0;
      padding-top: 56.3%;
      position: relative;
    }  
    .classname {
      font-size: 22px !important;
    }
    .live-title{
      color: white;
      font-weight: 600;
      font-size: 17px;
      display: inline-block;
      letter-spacing: -0.2px;
      line-height: 24px;
      text-align: left;
    }
    .time {
      font-size: 17px !important;
    }
    .cotd-time {
      font-family: SFProDisplay-Semibold;
      font-size: 22px !important;
    }
    .font-caption {
      font-size: 15px !important;
    }
    .live-time{
      color: white;
      font-size: 17px;
      display: inline-block;
      text-align: left;
      font-weight: bold;
      letter-spacing: -0.15px;
      line-height: 1.76;
    }
    .font-h1 {
      font-size: 40px !important;
    }
    .font-h6 {
      font-size: 17px !important;
    }
    .topkeyword{
      font-size: 15px !important;
    }
    .m-t-12{
      margin-top: 12px;
    }
    .cotd-date {
          font-size: 28px !important;
    }
    .Footer{
      margin: 0px 80px !important;
    }
    .container-1 {
      margin-right: 195px;
      margin-bottom: 16px;
    }
    .EContainer {
      margin: -10.9% auto 0px auto;
      .p-t-32{
        padding-top: 22px;
      }
    }
    .overlayWeb{
    .centeredText {
      height: 87%;};
    .image{
        height: 76.8vw;
        @media (min-width: 768px)  {
          height: 52vw;
        }
        @media (min-width: 768px)  {
          height: 52vw;
        }
      }
    }
    .lp-browse-class-container{
      position:relative;
      margin: 0px 102px 0px 88px;
      .m-t-60{
        margin-top: 0px;
      }
      .m-t-15{
        margin-top: 0px !important;
      }
    }

    $heightBrowseClassesLanding:calc((100vw - 350px)/6);
    .browseClasses-landing{
      @include cardSize($heightBrowseClassesLanding,$heightBrowseClassesLanding , 100%,100% ,$heightBrowseClassesLanding , 100%)
    }
    .list-browseClasses-landing {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-auto-flow: row ;
    }
    .class-title-text-holder {
      position: relative;
      margin: 0px 40px;
      background-color: white;
      padding: 28px 0px;
      border-radius: 8px 8px 0px 0px;
    }
  .hero-button{
    margin-top: 24px !important;
    .MuiTypography-button{
      font-size: 20px;
      font-family: SFProDisplay-Semibold;
    }
      width:280px !important;
      height:48px !important;
  }
  .SubscriptionHolder {
    display: block;
    margin-top: 45px !important;
    padding: 0px 103px !important;
  }
  .list-live-page{
    grid-template-columns: $widthLCC $widthLCC $widthLCC $widthLCC;
    grid-auto-flow: row ;
  }
  .topProfileLink{
    margin-top: 48px  !important;
    p {
    font-size: 40px }
  }
  .page-heading{
    position:absolute;
    top: 4.7vw;
    left: 5.85vw;
    text-align: left !important;
    h1{
      font-family: SFProDisplay-Semibold !important;
      font-size: 40px !important;
      line-height: 1;
      margin-bottom: 18px;
    }
  }
  .pseudo-div{
    margin-top: -8vw;
    height: 8vw;
  }
  .main-page-container {
    margin: 0 40px !important;
    padding: 0 40px;
    margin-top: -13vw !important;
    background-color: white;
    position: relative;
    border-radius: 8px;
  }
  .collectionPage-main{
    .main-page-container {
    margin-top: -14.5vw !important;
    }
    .pseudo-div {
      margin-top: -10vw;
      height: 10vw;
    }
  }
  .detail-page{
    .page-heading{
      top: 3.5vw;
      margin-right: 80px;
    }
  }
  .list-container-gap {
    padding-top: 40px ;
  }
  .channelbgimg {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .chnl-detail-desc{
    width: 808px;
  }
  .collection-page-list{
    padding-top: 16px;
    margin-bottom: 40px;
  }
  .collectionPage{
    h1{
      font-size: 28px !important;
    }
  }
  .collection-page-heading{
    text-align: left;
    h1{
      font-family: SFProDisplay-Semibold !important;
      font-size: 40px !important;
      line-height: 1;
      margin-bottom: 24px}
  }
  .collection-heading{
    margin-top: 48px;
    margin-bottom:60px;   
    h1{
      margin-bottom: 0;
    }
    h6{
      margin-top:16px;
      font-size: 17px;
    }
  }
  .category-tabs{
    margin-left:80px;
    margin-right:80px;
    margin-top: 21px;
    button{
      font-size: 22px !important;
      font-family: SFProDisplay-Semibold;

    }
  }
  .category-page-heading{
    position:absolute;
    top:64px;
    left: 80px;
    text-align: left;
    h1{
      display: block !important;
      font-family: SFProDisplay-Semibold;
      margin-top: 8px;
      margin-bottom: 16px;
    }
  }
  .category-page{
  .list-container-gap{
    padding-top: 32px;
  }
  .collectionShimmer{
    margin-top: 32px;
  }
  .bg-banner{
    height: 29vw;
    object-fit: cover;
  }
  .main-page-container{
    margin-top: -13vw !important;
  }
  }
  .channelbg-banner{
    .bg-banner{
      height: 29vw;
      object-fit: cover;
     }
  }
  .video-wrapper {
    width: 58.3vw;
    border-radius: 16px;
  }
  .video-js {
    width: 58.3vw;
    height: 32.7vw;
  }
  .video-paused-infobox {
    width: 58.3vw;
    height: 33vw;
  }
  .new-video-metadata {
    width: 58.3vw;
    h6 {
      font-size: 17px !important;
    }
  }
  .class-side-info {
    margin-left: 32px;
    .font-proDisplayBold{
      font-size: 17px !important;
      .value{
        font-size: 17px !important;
        }
    }
    .arrow-field{
      vertical-align: middle;
      height: 24px;
      width: 24px;
      margin-left: 4px;
    }
  }
  .more-class-arrow{
    vertical-align: middle;
    height: 32px;
    width: 32px;
    margin-left: 4px;
  }
  .customh1{
    font-size: 40px !important;
  }
  .featured-iconText-Player{
    font-size: 28px !important;
  }
  .font-proDisplayBold {
    font-size: 17px !important;
    .value{
    font-size: 17px !important;
    }
  }
  .meta-desc {
    width:58.3vw;
  }
  .progress-container{
    width:58.3vw
  }
  .barContainerStyles{
    height: 8px;
  }
  .intesity-logo{
    width: 30px;
    height:41px
  }
  .skill-logo{
    width: 36px;
    height:36px
  }
  .more-img{
    height: 50px;
    width: 50px;
  }
  .meta-arrow{
    vertical-align: bottom;
  }
  .sign-in-button{
    .MuiTypography-button{
    font-family: SFProDisplay-Semibold !important;
    font-size: 20px !important;
    }
  }
  .sign-up-button{
    .MuiTypography-button{
    font-family: SFProDisplay-Semibold !important;
    font-size: 17px !important;
    }
  }
  .staticBlock.scroll > div {
    margin: 0 80px;
    width: auto;
  }
  .subscribe-input {
    font-size: 20px !important;
    font-family: SFProDisplay-Semibold !important;
  }
  .subscribe-field{
    padding-top: 30px;
  }
  .button-link{
    font-size: 17px !important;
    font-family: SFProDisplay-Regular !important;
  }
  .login-title, .err-header, .reset-password, .subscription-title{
    font-size: 40px !important;
    h1{
      font-size: 40px !important;
    }
  }
  .login-subtitle h6, .subscription-sub-title{
    font-size: 17px !important;
  }
  .login-disclaimer {
    font-size: 15px !important;
  }
  .no-account{
    font-size: 17px !important;
  }
  .livePlayer-mt{
    margin-top: 40px;
  }
  .labelStyles{
    font-size:20px !important;
  }
  .m-t-account{
    margin-top: 24px;
  }
  .m-t-subs{
    margin-top: 16px !important;
  }
  form.editForm {
    margin: 36px auto;
  }
  .livecard-label {
    right: 27px;
  }
  .live-label {
    font-size: 10px;
  }
  .cotd-date{
    position:absolute;
    top: -5px
  }
  .m-b-contact{
    margin-bottom: 80px !important;
  }
  .p-t-player{
    padding-top: 24px;
  }
  .new-video-metadata {
    margin-top: 36px;
  }
  .m-more-class{
    margin-top: 84px;
  }
  .m-more-head{
    margin: 26px 0px 16px 0px !important;
  }
  .rec-class-title{
    margin-bottom: 24px !important;
  }
  .topProfileLink-margin{
    margin-right: 28px !important;
  }
  // .res-fav-acc{
  //   .topProfileLink-margin{
  //   margin-bottom: 18px !important;
  //   }
  // }
  .marketing-banner{
    top:0vw !important;
  }
  .loginButtonHolder a .MuiTypography-button {
    font-size: 17px ;
  }
  .headerLinks-fitness {
    margin-left: 16px;
  }
  .lang-sel-cont{
    margin-top: 60px !important;
  }
  .more-heading{
    p {
    font-size: 20px !important;
    }
  } 
  .label-logo{
    width: 36px
  }
  .bar-container{
    left:160px
    }
  .live-leave{
    width: 58.3vw;
    top: 39px !important;
    height: 33vw;
  }
  .livePlayer-info2{
    width: 58vw;
    top: 44px;
  }
  .card-footer{
    margin: 9px 0 6.2px 0px;
   }
   .vido-metadata-row {
    margin-top: 32px;
  }
  .no-classes-found{
    margin-top: 48px;
    p{
      font-size: 17px;
    }
  }
  .Trailer, .thumbnail, .Trailer:focus{
    max-height: 15.9vw !important;
  }
  .height-fix{
    height: 30.05px !important;
  }
  .not-found{
    margin-top: 10px !important;
  }
  .m-t-subscription-title{
    margin-top: 0px !important;
  }
  .MuiFormHelperText-root.Mui-error{
    color: #ff0000;
    font-size: 15px;
}
  .shareViewRowWidth{
    width: 58.3vw;
  }
  .shareLiveViewRowWidth{
    min-width: 56.8vw;
  }
  .modal-title{
    font-size: 40px;
  }
  .fac-subs{
    button {
      margin-top: 56px !important;
    }
  }
  .share-btn-align{
    margin-top: 15px;
  }
  .padding-top-tnc-accept{
    padding-top: 40px;
  }
  .sharePadding16Right {
    padding-right: 12px !important;
  }
  .live-title-truncate{
    margin-right: 62px
  }
}

   @media only screen and (min-width: 2000px)  {
    $widthLCC:calc((100vw - 160px - 64px - 15px)/5); //refer line 192 for understanding width calc bifurcation 
    $widthCotd:calc((100vw - 160px - 32px - 15px)/3);
    $widthChannel:calc((100vw - 160px - 48px - 15px)/4);
    $widthBrowseClasses:calc((100vw - 160px - 160px - 15px)/10);
    $heightBrowseClassesLanding:calc((100vw - 384px)/10);
    .browseClasses-landing{
      @include cardSize($heightBrowseClassesLanding,$heightBrowseClassesLanding , 100%,100% ,$heightBrowseClassesLanding , 100%)
    }
    .list{
      grid-template-columns: repeat(5,$widthLCC);
      grid-gap: 16px;
      overflow-x: hidden;
    }
    .font-h6 {
      font-size: 20px !important;
    }
    .live-channel-collection {
      @include cardSize(auto,auto,$widthLCC,$widthLCC,auto,$widthLCC);
      width: $widthLCC;
      padding-top: 56.3%;
      position: relative;
    }
    .channel-page-card {
      @include cardSize(auto,auto,$widthLCC,$widthLCC,auto,$widthLCC);
      width: $widthChannel;
      padding-top: 56.3%;
      position: relative;
  }
  .browseClasses{
    @include cardSize($widthBrowseClasses,$widthBrowseClasses,$widthBrowseClasses,$widthBrowseClasses,$widthBrowseClasses,$widthBrowseClasses);
  }
  .cotd-card{
    @include cardSize(auto,auto,$widthCotd,$widthCotd,auto,$widthCotd);
    width: $widthCotd !important;
    top: 0;
    padding-top: 56.3%;
    position: relative;
   }  
   .cotd-list{
    grid-template-columns: repeat(3,$widthCotd);
    grid-gap: 16px;
    
  }
  .font-caption {
    font-size: 15px !important;
  }  
.time{
  margin-bottom: 5px;
  font-size: 17px !important;
}
.imageHolder {
  border-radius: 8px;
}
.imageHolder::after{
  border-radius: 8px;
}
.cotd-overlay::after {
  border-radius: 8px 8px 0 0;
  height:25%;
}
.channelname {
  font-size: 22px !important;}
.Footer h3 {
    font-size: 17px;}
.classname {
      font-size: 28px !important;
    }

    .list-browseClasses{
      grid-template-columns: repeat(auto-fill,$widthBrowseClasses);
      overflow-x: scroll;
      scroll-behavior: smooth;
      scrollbar-width: none;
    }

  .EContainer {
    margin: -11.5% auto 0px auto;
    .p-t-32{
      padding-top: 22px;
    }
  }
  .lp-browse-class-container{
    position:relative;
    margin: 0 40px
  }


  $heightBrowseClassesLanding:calc((100vw - 321px)/10);
  .browseClasses-landing{
    @include cardSize($heightBrowseClassesLanding,$heightBrowseClassesLanding , 100%,100% ,$heightBrowseClassesLanding , 100%)
  }
  .list-browseClasses-landing {
    grid-template-columns: repeat(auto-fill,$heightBrowseClassesLanding);
    grid-auto-flow: row ;
  }
  .list-live-page{
  grid-template-columns: $widthLCC $widthLCC $widthLCC $widthLCC $widthLCC;
  grid-auto-flow: row ;
}

.page-heading{
  position:absolute;
  top: 6.3vw;
  left: 4vw;
  text-align: left !important;
  h1{
    margin-bottom: 16px;
    font-size: 70px !important;
  }
}
.pseudo-div{
  margin-top: -8vw;
  height: 8vw;
}
.main-page-container {
  margin: 0 40px !important;
  padding: 0 40px;
  margin-top: -11.2vw !important;
  background-color: white;
  position: relative;
  border-radius: 8px;
}
.collectionPage-main{
  .main-page-container {
  margin-top: -12.2vw !important;
  }
  .pseudo-div {
    margin-top: -9vw;
    height: 9vw;
  }
}
.list-container-gap {
  padding-top: 40px ;
}
.channel-profileImg {
  margin-right: 31px;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  cursor: pointer;
}
.chnl-detail-desc{
  width: 904px
}
.detail-page{
  .page-heading{
    top: 2.4vw;
    margin-right: 80px;
  }
}
.collectionPage{
  h1{
   font-size: 40px !important;
   }
}
.collection-page-list{
  padding-top: 16px;
  margin-bottom: 64px;
}

.collection-heading{
  margin-top: 48px;
  margin-bottom:76px;   
  h1{
    font-size: 70px !important;
    margin-bottom: 0
  }
  h6{
    margin-top:12px;
    font-size: 20px;
  }
}

.collection-page-heading{
  text-align: left;
  h1{
    font-family: SFProDisplay-Semibold !important;
    font-size: 70px !important;
   }
}

.category-tabs{
  margin-left:80px;
  margin-right:80px;
  margin-top: 25px;
  button{
    font-size: 22px !important;
    font-family: SFProDisplay-Semibold;

  }
}
.category-page-heading{
  position:absolute;
  top:112px;
  left: 80px;
  text-align: left;
  h1{
    display: block !important;
    font-size: 70px !important;
    font-family: SFProDisplay-Semibold;
    margin-top: 8px;
    margin-bottom: 0px;
  }
}
.category-page{
 .list-container-gap{
  padding-top: 32px;
 }
 .collectionShimmer{
  margin-top: 32px;
 }
 .bg-banner{
  height: 21.3vw;
  object-fit: cover;
 }
 .main-page-container{
  margin-top: -3.2vw !important;
 }
}
.video-wrapper {
  // width: 44vw;
  border-radius: 16px;
}
// .video-js {
//   width: 44vw;
//   height: 24.7vw;
// }
// .video-paused-infobox {
//   width: 44vw;
//   height: 24.7vw;
// }
.new-video-metadata {
  // width: 44vw;
  margin-top: 52px;
  h6 {
    font-size: 20px !important;
  }
}
.class-side-info {
  margin-left: 40px;
  .font-proDisplayBold{
    font-size: 20px !important;
    .value{
      font-size: 20px !important;
      }
  }
  .arrow-field{
    vertical-align: middle;
    height: 32px;
    width: 32px;
    margin-left: 4px;
  }
}
.customh1{
  font-size: 70px !important;
}
.featured-iconText-Player{
  font-size: 28px !important;
}
.font-proDisplayBold {
  font-size: 20px !important;
  .value{
  font-size: 20px !important;
  }
}
// .meta-desc {
//   width:44vw
// }
.bar-container{
left:180px
}

.meta-arrow{
  width: 32px !important;
  height: 32px !important;
  vertical-align: middle;
}
.subscribe-status{
  font-size: 15px !important;
}
.login-title, .err-header, .reset-password, .subscription-title{
  font-size: 70px !important;
  h1{
    font-size: 70px !important;
  }
}
.login-subtitle h6, .subscription-sub-title{
  font-size: 20px !important;
}
.left-arrow{
  padding: 10px 0px;
}
.m-b-info-cont{
  margin-bottom: 24px;
}
.m-more-class{
  margin-top: 120px;
}
.m-payment-Done-btn{
  margin-top: 54px!important;
}
.subscriptionTagline {
  margin-bottom: 48px;
}
.subscriptionPlanTitle {
  font-size: 22px !important;
}
.m-b-contact{
  margin-bottom: 60px !important;
}
.m-b-title{
  margin-bottom: 60px !important;
}
.p-t-checkout{
  padding-top: 16px;
}
.cotd-date{
  top: 4px
}
.rec-class-title{
  p{
    font-size: 20px;
  }
  margin-top: 64px !important;
}
.livePlayer-mt{
  margin-top: 40px;
}
.marketing-banner{
  top:1vw !important;
}
.lang-sel-cont{
  margin-top: 106px !important;
}
.channelbgimg {
    margin-bottom: 38px;
}
.live-leave{
  top: 40px !important;
}
.livePlayer-info2{
  top: 58px;
}
.marginFormTop {
  margin-top: 56px;
}
.vido-metadata-row {
  margin-top: 48px;
}
.cotd-time {
  font-family: SFProDisplay-Semibold;
  font-size: 22px !important;
}
.topKeySpace{
  .MuiTypography-caption{
    font-size: 15px;
  }
}
.topkeyword{
  font-size: 15px !important;
}
.no-classes-found{
  margin-top: 48px;
  p{
    font-size: 20px;
  }
}
.Trailer, .thumbnail, .Trailer:focus{
  max-height: 16.8vw !important;
}
.not-found{
  margin-top: 30px;
}
.m-t-payment{
  margin-top: 50px !important;
}
.m-t-subscription-title{
  margin-top: 20px !important;
}
.m-t-contact,
.m-t-xl-err{
  margin-top: 38px !important;
}
.m-t-sent-mail{
  margin-top: 48px !important;
}
.m-t-account{
  margin-top: 38px;
}
form.editForm {
  margin: 48px auto;
}
.padding-top-tnc-accept{
  padding-top: 48px;
}
.sharePadding16Right {
  padding-right: 16px !important;
}
  }