.icon-svg{
    background-color: var(--main-primary-color);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    cursor: pointer;
    z-index: 5;
    @media screen and (min-width:1360px) {
        width: 40px;
        height: 40px;
        }
        @media screen and (min-width:2000px) {
        width: 48px;
        height: 48px; 
        }
    
    &-star{
        pointer-events: none;
        z-index: 1;
        transform: translate(9%, 7%);
        @media screen and (min-width:1360px) {
        transform: translate(19%, 19%); 
        }
        @media screen and (min-width:2000px) {
        transform: translate(25%, 25%); 
        }
    }
    &-share{
        pointer-events: none;
        z-index: 1;
        transform: translate(18%, 13%);
        @media screen and (min-width:1360px) {
        transform: translate(25%, 21%); 
        }
        @media screen and (min-width:2000px) {
        transform: translate(30%, 27%); 
        }
    }
}