
.tncbox {
  font-family: SFProDisplay-Semibold;
  max-width: 80%;
  margin-left: 10%;
  text-align: justify;
  h5{
    strong {
    font-size: 15px;
    @media only screen and (min-width: 768px)  {
      font-size: 18px;
    }
    @media only screen and (min-width: 1360px)  {
      font-size: 20px;
    }
    }
  }
  li,p {
    font-size: 14px;
    font-family: SFProDisplay-Regular
  }
  h2{
    font-size: 24px;
  }
  h3{
    font-size: 20px;
  }
  .tnctexts {
    text-align: justify !important;
  }
  .tnclistsitem {
    margin-left: 1.5%;
    text-align: justify;
  }
  .tnc-list-style{
    list-style: none;
  }
  .innertnclistsitem {
    margin-left: 2%;
    text-align: justify;
  }
  ol {
    margin: 0;
    padding: 0;
  }

  table td,
  table th {
    padding: 0;
  }
}
